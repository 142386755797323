import { Component } from '@angular/core';
import { MenutoggleService } from './menutoggle.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isSidebarVisible =  false;
    _subscription: any;
  title = 'fresh';
  open = false;

  constructor(private menuServices:MenutoggleService) {


    this._subscription = menuServices.sidebarVisibilityChange.subscribe((value) => {
    this.isSidebarVisible = value;
  });


  }
  ngOnInit(): void {

  }

  toggleSidebar() {
    this.menuServices.toggleSidebarVisibilty();

  }
}
