import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* compoenentes */
import { MenuComponent } from './menu/menu.component';
import { ContactoComponent } from './contacto/contacto.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { IndexComponent } from './index/index.component';
import { DashboardComponent } from './dashboard/dashboard.component';

/* Angular material */
import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Pedidos2Component } from './pedidos2/pedidos2.component';
import { Pedido3Component } from './pedido3/pedido3.component';
import { MenutoggleService } from './menutoggle.service';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogBaguetteComponent } from './forms/dialog-baguette/dialog-baguette.component';
import { DialogOrdComponent } from './forms/dialog-ord/dialog-ord.component';
import { DialogEnsaladaComponent } from './forms/dialog-ensalada/dialog-ensalada.component';
import { DialogHuevosComponent } from './forms/dialog-huevos/dialog-huevos.component';
import { DialogOmeletComponent } from './forms/dialog-omelet/dialog-omelet.component';
import { DialogCarritoComponent } from './forms/dialog-carrito/dialog-carrito.component';
import { DialogChilaquilesComponent } from './forms/dialog-chilaquiles/dialog-chilaquiles.component';
import { DialogWrapsComponent } from './forms/dialog-wraps/dialog-wraps.component';
import { DialogTendersComponent } from './forms/dialog-tenders/dialog-tenders.component';
import { DialogQuesadillasComponent } from './forms/dialog-quesadillas/dialog-quesadillas.component';
import { DialogMolletesComponent } from './forms/dialog-molletes/dialog-molletes.component';
import { DialogAlitasComponent } from './forms/dialog-alitas/dialog-alitas.component';
import { DialogTacosfreshComponent } from './forms/dialog-tacosfresh/dialog-tacosfresh.component';

import { DialogPastaComponent } from './forms/dialog-pasta/dialog-pasta.component';
import { DialogRamenComponent } from './forms/dialog-ramen/dialog-ramen.component';
import { DialogAguaFrutasComponent } from './forms/dialog-agua-frutas/dialog-agua-frutas.component';
import { DialogAguaFrutastemporadaComponent } from './forms/dialog-agua-frutastemporada/dialog-agua-frutastemporada.component';
import { DialogLicuadosComponent } from './forms/dialog-licuados/dialog-licuados.component';
import { DialogAguaFrutascombinadaComponent } from './forms/dialog-agua-frutascombinada/dialog-agua-frutascombinada.component';
import { DialogJugonaranjaComponent } from './forms/dialog-jugonaranja/dialog-jugonaranja.component';
import { DialogRefrescosembotelladosComponent } from './forms/dialog-refrescosembotellados/dialog-refrescosembotellados.component';
import { DialogJugofrutascombinadaComponent } from './forms/dialog-jugofrutascombinada/dialog-jugofrutascombinada.component';
import { DialogCoctelfrutasComponent } from './forms/dialog-coctelfrutas/dialog-coctelfrutas.component';
import { DialogPaqueteDesayunoComponent } from './forms/dialog-paquete-desayuno/dialog-paquete-desayuno.component';
import { DialogEnchiladaComponent } from './forms/dialog-enchilada/dialog-enchilada.component';
import { DialogHotcakesComponent } from './forms/dialog-hotcakes/dialog-hotcakes.component';

/* Firebase */

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule  } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule  } from '@angular/fire/analytics';
/* enviroment */
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ContactoComponent,
    SucursalesComponent,
    NosotrosComponent,
    PedidosComponent,
    IndexComponent,
    DashboardComponent,
    Pedidos2Component,
    Pedido3Component,
    FooterComponent,
    DialogBaguetteComponent,
    DialogOrdComponent,
    DialogEnsaladaComponent,
    DialogHuevosComponent,
    DialogOmeletComponent,
    DialogCarritoComponent,
    DialogChilaquilesComponent,
    DialogWrapsComponent,
    DialogTendersComponent,
    DialogQuesadillasComponent,
    DialogMolletesComponent,
    DialogAlitasComponent,
    DialogTacosfreshComponent,
    DialogPastaComponent,
    DialogRamenComponent,
    DialogAguaFrutasComponent,
    DialogAguaFrutastemporadaComponent,
    DialogLicuadosComponent,
    DialogAguaFrutascombinadaComponent,
    DialogJugonaranjaComponent,
    DialogRefrescosembotelladosComponent,
    DialogJugofrutascombinadaComponent,
    DialogCoctelfrutasComponent,
    DialogPaqueteDesayunoComponent,
    DialogEnchiladaComponent,
    DialogHotcakesComponent
  ],
  entryComponents:[
    DialogBaguetteComponent,
    DialogEnsaladaComponent,
    DialogHuevosComponent,
    DialogOmeletComponent,
    DialogOrdComponent,
    DialogCarritoComponent
  ],
  imports: [
    BrowserModule,

    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    /* Angular material */
    MatSliderModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    /* firebase */
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAnalyticsModule
  ],
  providers: [MenutoggleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
