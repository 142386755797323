import { Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ContactoComponent } from '../contacto/contacto.component';
import { MenuComponent } from '../menu/menu.component';
import { NosotrosComponent } from '../nosotros/nosotros.component';
import { SucursalesComponent } from '../sucursales/sucursales.component';
import { PedidosComponent } from '../pedidos/pedidos.component';
import { Pedidos2Component } from '../pedidos2/pedidos2.component';
import { Pedido3Component } from '../pedido3/pedido3.component';

export const indexRoutes: Routes = [

{path: '', component : DashboardComponent},
{path: 'contacto', component : ContactoComponent},
{path: 'menu', component : MenuComponent},
{path: 'nosotros', component : NosotrosComponent},
{path: 'pedido', component: PedidosComponent},
{path: 'ordenar', component: Pedidos2Component},
{path: 'sucursales', component : SucursalesComponent},
{path: 'pedidomas', component : Pedido3Component},
{path: '**', redirectTo: 'inicio'}


];

