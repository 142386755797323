<mat-sidenav-container>
    <!-- mode= side over push -->
    <mat-sidenav #sidenav role="navigation" mode="over" [(opened)]="asideVisible">

        <nav  class=" nav flex-column nav-lateral sc">

            <li class="nav-item  ">
                <a class="nav-link backa" tabindex="-1"  routerLink="/" routerLinkActive="seleccionado"   [routerLinkActiveOptions]="{ exact: true }">
                    <span>Inicio</span>
                </a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" tabindex="-1" routerLink="/ordenar" routerLinkActive="seleccionado">
                    <span>Menú</span>
                </a>
            </li> -->
            <li class="nav-item">

                <a class="nav-link" tabindex="-1" routerLink="/ordenar" routerLinkActive="seleccionado" (click)="contrario()">
                    <span>Ordenar</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" tabindex="-1" routerLink="/nosotros" routerLinkActive="seleccionado">
                    <span>Nosotros</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" tabindex="-1" routerLink="/sucursales" routerLinkActive="seleccionado">
                    <span>Sucursales</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" tabindex="-1" routerLink="/contacto" routerLinkActive="seleccionado">
                    <span>Contacto</span>
                </a>
            </li>
            <hr>
            <div class="container text-center">

                <a class="espacio" tabindex="-1" href="https://www.facebook.com/freshngreencampeche.centrocampeche" target="_blank">
                    <mat-icon class="icono-nav">facebook</mat-icon>
                </a>
             <!--    <a class="espacio" tabindex="-1"href="https://wa.me/529812072331&text=Hola,%20deseó%20ordenar">
                    <mat-icon class="icono-nav">whatsapp</mat-icon>
                </a> -->
                <a class="espacio" tabindex="-1"href="https://api.whatsapp.com/send?phone=529812072331&text=Hola,%20deseó%20ordenar">
                    <mat-icon class="icono-nav">whatsapp</mat-icon>
                </a>

            </div>

        </nav>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="container-fluid" class="fixed-top">
            <div class="d-flex ">
                <div class="p-2 w-100  ">
                    <!--  <button mat-flat-button class="verde">Ordenar ensalada</button> -->

                </div>
                <div class="p-2">

                    <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>

    </mat-sidenav-content>
</mat-sidenav-container>
