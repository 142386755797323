import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Orden } from '../../clases/orden';

@Component({
  selector: 'app-dialog-baguette',
  templateUrl: './dialog-baguette.component.html',
  styleUrls: ['./dialog-baguette.component.css']
})
export class DialogBaguetteComponent implements OnInit {

  description = 'Selecciona tu Baguette';
  baguetteForm: FormGroup;
  toggleVeg = false;
  toggleFran = false;
  ingredientess: Array<any> = [
    'Zanahoria',
    'Tomate',
    'Betabel',
    'Champinon',
    'Jalapeno',
    'Cebolla',
    'Aceituna',
    'Piña',
    'Morron',
    'Panela',
    'Pasta',
    'Apio',
    'Colmorado',
    'Pepino',
    'Espinaca',
    'Germinado',
    'Elote',
    'Brocoli'
  ];
  tipobaguette:string;
  selectedingredientes = [] as any;
  todos: any;
  get ingredientesArray(): any{
    return this.baguetteForm.get('ingredientes')as FormArray;
  }

  constructor( public fb: FormBuilder,
               private dialogRef: MatDialogRef<DialogBaguetteComponent>,
               @Inject(MAT_DIALOG_DATA) data: Orden) {

    this.baguetteForm = this.fb.group({
      tipo: new FormControl('', Validators.required),
      aderezo: new FormControl('' , Validators.required ),
      proteina: new FormControl(''),
      ingredientes : this.addingredientes(),
      seleccionados: new FormControl('', Validators.required),

    });
    this.selectedingredientes = [] as any ;
    this.tipobaguette='';
  }

  ngOnInit(): void {
    this.baguetteForm = this.fb.group({
      tipo: new FormControl('', Validators.required),
      aderezo: new FormControl('' , Validators.required ),
      proteina: new FormControl(''),
      ingredientes : this.addingredientes(),
      seleccionados: new FormControl(''),
    });

  }
  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });

    return this.fb.array(arr);

  }
  getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        if (this.selectedingredientes.length < 4){
           this.selectedingredientes.push(this.ingredientess[i]);
        }

      }
    });
    /* console.log(this.ingredientesArray.controls[0].value); */

    this.baguetteForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));
    /* console.log(this.selectedingredientes); */

    /* this.baguetteForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));
 */
   /*  console.log(this.selectedingredientes.join(',')); */
  }

  comprobarBagVegetaria(i: any): any{

  const b = this.baguetteForm.value.tipo;

  let valorCambiado:number = 0;

  if(b === 'frances'){
    this.tipobaguette = 'Pollo y queso';
  }
  if(b === 'marino'){
    this.tipobaguette = 'Atún y queso';
  }
  if(b === 'light'){
    this.tipobaguette = 'Jamón  de pavo y panela';
  }
  if(b === 'italiano'){
    this.tipobaguette = 'Pepperoni y  queso';
  }
  if(b === 'veneciano'){
    this.tipobaguette = 'Salami, morrón y queso';
  }
  if(b === 'suizo'){
    this.tipobaguette = 'Queso, panela y parmesano';
  }
  if(b === 'vegetariano'){
    this.tipobaguette = '4 verduras a tu gusto';
  }
  if(b === 'hawaiiano'){
    this.tipobaguette = 'Jamón de pierna, piña y queso';
  }
  if(b === 'tradicional'){
    this.tipobaguette = 'Jamón de pierna y queso';
  }
  if(b === 'mediterraneo'){
    this.tipobaguette = 'Panela, aceitunas, pepperoni y germinado de trigo';
  }
  if(b === 'napolitano'){
    this.tipobaguette = 'Pepperoni, champiñón, salsa de pizza y queso';
  }

  if(b === 'frances'){

    this.toggleFran = true;
  }
  if (b != 'vegetariano'){
    this.toggleVeg = false;

  }else{
    this.toggleVeg = true;
    this.toggleFran = false;
  }
  if(b != 'vegetariano' && b != 'frances'){
    this.toggleVeg = false;
    this.toggleFran = false;
    this.baguetteForm.controls.proteina.setValue('');
  }

    /* si no es frances o no esta true el tooglefran tons si */
  /* if (b != 'frances' || this.toggleFran === true){
      this.toggleFran = false;
     }else{
      this.toggleFran = true;
     } */

  }
  voltear(evento:any){

    this.baguetteForm.controls.proteina.setValue('');
    let b = this.baguetteForm.value.tipo;


    if(b =='frances' && evento.checked === true){
      this.toggleFran = true;
    }else{
      this.toggleFran = false;
    }
    if( b !='frances' && evento.checked === true){
      this.toggleFran = true;
    }else{
      this.toggleFran = false;
    }



  }

  close(){
    this.dialogRef.close();
  }

  save(){
    if (this.baguetteForm.value.tipo === '' && this.baguetteForm.value.aderezo === ''){
      console.log('no hay datos: secundario');
    }
    if (this.baguetteForm.value){
      this.dialogRef.close(this.baguetteForm.value);
    }
  }

}
