import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-wraps',
  templateUrl: './dialog-wraps.component.html',
  styleUrls: ['./dialog-wraps.component.css']
})
export class DialogWrapsComponent implements OnInit {

  wrapForm: FormGroup;
  ingredientess: Array<any> = [
    'Zanahoria',
    'Tomate',
    'Betabel',
    'Champinon',
    'Jalapeno',
    'Cebolla',
    'Aceituna',
    'Piña',
    'Morron',
    'Panela',
    'Pasta',
    'Apio',
    'Colmorado',
    'Pepino',
    'Espinaca',
    'Germinado',
    'Elote',
    'Brocoli'
  ];
  toggleVeg = false;
  toggleFran = false;
  get ingredientesArray(): any{
    return this.wrapForm.get('ingredientes')as FormArray;
  }
  selectedingredientes = [] as any;

  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogWrapsComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {

      this.wrapForm = this.fb.group({
        tipo: new FormControl('', Validators.required),
        aderezo: new FormControl('' , Validators.required ),
        ingredientes : this.addingredientes(),
        seleccionados: new FormControl(''),

      });
      this.selectedingredientes = [] as any ;
     }

  ngOnInit(): void {
    this.wrapForm = this.fb.group({
      tipo: new FormControl('', Validators.required),
      aderezo: new FormControl('' , Validators.required ),
      ingredientes : this.addingredientes(),
      seleccionados: new FormControl(''),

    });
  }

  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });

    return this.fb.array(arr);

  }
  getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        if (this.selectedingredientes.length < 4){
           this.selectedingredientes.push(this.ingredientess[i]);
        }

      }
    });

    this.wrapForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));


    /* this.wrapForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));
 */
   /*  console.log(this.selectedingredientes.join(',')); */
  }
  comprobarBagVegetaria(i: any): any{

    const b = this.wrapForm.value.tipo;

    if (b != 'vegetariano'){
       this.toggleVeg = false;
      }else{
         this.toggleVeg = true;
      }

      /* si no es frances o no esta true el tooglefan tons si */
    if (b != 'frances' || this.toggleFran === true){
        this.toggleFran = false;
       }else{
        this.toggleFran = true;
       }

  }
  voltear(): any{
    this.toggleFran = !this.toggleFran;

  }

  close(): any{
    this.dialogRef.close();
  }
  save(): any{
    if (this.wrapForm.value.tipo === '' && this.wrapForm.value.aderezo === ''){
      console.log('no hay datos: secundario');
    }
    if (this.wrapForm.value){
      this.dialogRef.close(this.wrapForm.value);
    }
  }
}
