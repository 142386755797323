<div class="contenido">
    <div class="row justify-content-center">

        <div id="carouselExampleSlidesOnly" class="carousel  slide carousel-fade  pb-2" data-bs-ride="carousel">

            <div class="carousel-indicators pt-5">

                <button type="button" class="circulo" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="3" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="4" aria-label="Slide 4"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active " data-bs-interval="10000">
                    <img src="assets/fr2.png" class=" d-block w-100 " alt="...">
                </div>
                <div class="carousel-item" data-bs-interval="10000">
                    <img src="assets/fr2.png" class="d-block w-100 " alt="..." loading="lazy">
                </div>
                <div class="carousel-item" data-bs-interval="10000">
                    <img src="assets/fr2.png" class="d-block w-100 " alt="..." loading="lazy">
                </div>
                <div class="carousel-item" data-bs-interval="10000">
                    <img src="assets/fr2.png" class="d-block w-100 " alt="..." loading="lazy">
                </div>
                <div class="carousel-item" data-bs-interval="10000">
                    <img src="assets/fr2.png" class="d-block w-100 " alt="..." loading="lazy">
                </div>
            </div>
            <!-- <img src="assets/logo-f1.png" class=" d-block w-100 " alt="..."> -->
        </div>


        <div class="card-group">
            <div class="row ">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="card arribaAbajo ">
                        <div class="pos">
                            <img loading="lazy" class="card-img-top" src="assets/f4Reducida1.png" alt="Card image cap">
                        </div>
                        <div class="verde ">
                            <button class="boton" (click)="irPedido()" mat-flat-button>Haz tu pedido</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="card  arribaAbajo">
                        <div class="pos">
                            <img loading="lazy" class="card-img-top" src="assets/f5Reducida1.png" alt="Card image cap">
                        </div>
                        <div class="verde ">
                            <button class="boton" (click)="irPedido()" mat-flat-button>Menú</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-group">

            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="card arribaAbajo">
                        <div class="pos">
                            <img loading="lazy" class="card-img-top" src="assets/freshpersonas11.png" alt="Card image cap">
                        </div>
                        <div class="verde ">
                            <button class="boton" (click)="irnosotros()" mat-flat-button>Nosotros</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="card arribaAbajo">
                        <div class="pos">
                            <img loading="lazy" class="card-img-top" src="assets/f6Reducida3.png" alt="Card image cap">
                        </div>
                        <div class="verde">
                            <button class="boton" (click)="ircontacto()" mat-flat-button>Contacto</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-footer></app-footer>
