import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-huevos',
  templateUrl: './dialog-huevos.component.html',
  styleUrls: ['./dialog-huevos.component.css']
})
export class DialogHuevosComponent implements OnInit {
  huevoForm: FormGroup;
  ingredientess: Array<any> = [
    'Zanahoria',
    'Tomate',
    'Betabel',
    'Champinon',
    'Jalapeno',
    'Cebolla',
    'Aceituna',
    'Piña',
    'Morron',
    'Panela',
    'Pasta',
    'Apio',
    'Colmorado',
    'Pepino',
    'Espinaca',
    'Germinado',
    'Elote',
    'Brocoli'
  ];
  toggleVeg = false;
  get ingredientesArray(): any{
    return this.huevoForm.get('ingredientes')as FormArray;
  }
  selectedingredientes = [] as any;

  constructor(public fb: FormBuilder,
              private dialogRef: MatDialogRef<DialogHuevosComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {

                this.huevoForm = this.fb.group({
                  tipo: new FormControl('', Validators.required),
                  ingredientes : this.addingredientes(),
                  seleccionados: new FormControl(''),

                });
                this.selectedingredientes = [] as any ;
               }

  ngOnInit(): void {
    this.huevoForm = this.fb.group({
      tipo: new FormControl('', Validators.required),
      ingredientes : this.addingredientes(),
      seleccionados: new FormControl(''),

    });
  }
  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });

    return this.fb.array(arr);

  }
  getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        if (this.selectedingredientes.length < 4){
           this.selectedingredientes.push(this.ingredientess[i]);
        }

      }
    });

    this.huevoForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));

  }
  comprobarBagVegetaria(i: any): any{

    const b = this.huevoForm.value.tipo;

    if (b !== 'revueltos'){
       this.toggleVeg = false;
      }else{
         this.toggleVeg = true;
      }
  }

  close(): any{
    this.dialogRef.close();
  }
  save(): any{
    if (this.huevoForm.value.tipo === '' ){
      console.log('no hay datos: secundario');
    }
    if (this.huevoForm.value){
      this.dialogRef.close(this.huevoForm.value);
    }
  }

}
