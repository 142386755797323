// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  firebase:{
    apiKey: "AIzaSyBncnx-MfmqJaILoldw94TMnyu4MCjMWKQ",
    authDomain: "freshngreencampeche.firebaseapp.com",
    projectId: "freshngreencampeche",
    storageBucket: "freshngreencampeche.appspot.com",
    messagingSenderId: "191928325935",
    appId: "1:191928325935:web:41978e3af400a4ab88b3da",
    measurementId: "G-DYH8CT148X"
  },
  mapboxKey:'pk.eyJ1Ijoic3Jtb2F6IiwiYSI6ImNrcWlrdnp0czAwNHEydm8yZWxtbHUzYmIifQ.nGdkm-pPF9uR6TYoBOQMuQ'

};


/* apiKey: "AIzaSyC9XHFwcq16xXK0XbYPAoAKQlhn6puLLl0",
  authDomain: "fresh-9c1df.firebaseapp.com",
  projectId: "fresh-9c1df",
  storageBucket: "fresh-9c1df.firebasestorage.app",
  messagingSenderId: "1083838800898",
  appId: "1:1083838800898:web:3d1a2dcc16420f7b2af31b",
  measurementId: "G-B3PEESBSR2" */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
