<div class="contenido">
    <img  src="assets/fr2.png" class=" w-100 espacio" alt="...">

    <header class="text-center my-4">
      <h1 class="mt-4 mb-2">Fresh and Green Campeche</h1>
      <p class="lead">Disfruta de una experiencia saludable en el corazón de Campeche</p>
    </header>

   <!-- SECCIÓN: Carrusel de imágenes -->
   <section aria-label="Galería de Fresh and Green" class="row justify-content-center mb-5">
    <div class="col-lg-5">
      <div id="carouselExampleSlidesOnly1" class="carousel carousel-dark slide carousel-fade" data-bs-ride="carousel">
        <!-- Indicadores -->
        <div class="carousel-indicators pt-5">
          <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleSlidesOnly1" data-bs-slide-to="4" aria-label="Slide 5"></button>
        </div>
        <!-- Contenido del carrusel -->
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="3000">
            <img src="assets/L1.jpg" class="d-block w-100" alt="Imagen 1 de Fresh and Green Campeche">
          </div>
          <div class="carousel-item" data-bs-interval="3000">
            <img src="assets/L2.jpg" class="d-block w-100" alt="Imagen 2 de Fresh and Green Campeche">
          </div>
          <div class="carousel-item" data-bs-interval="3000">
            <img src="assets/L3.jpg" class="d-block w-100" alt="Imagen 3 de Fresh and Green Campeche">
          </div>
          <div class="carousel-item" data-bs-interval="3000">
            <img src="assets/L4.jpg" class="d-block w-100" alt="Imagen 4 de Fresh and Green Campeche">
          </div>
          <div class="carousel-item" data-bs-interval="3000">
            <img src="assets/L5.jpg" class="d-block w-100" alt="Imagen 5 de Fresh and Green Campeche">
          </div>
        </div>
      </div>
    </div>
  </section>

      <!-- SECCIÓN: Mapa de ubicación -->
  <section class="mapa-ubicacion container mb-5">
    <h2 class="mb-3">Nuestra Ubicación en la Calle 59</h2>
    <p>
      Nos encontramos en la legendaria calle 59, reconocida por sus fachadas de colores,
      cafés pintorescos y ambiente colonial. Esta calle peatonal es ideal para pasear,
      admirar la arquitectura del centro y disfrutar de la gastronomía local.
    </p>
    <div class="row justify-content-center mt-4">
      <div class="col-lg-12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7505.91975076336!2d-90.53549320072018!3d19.841650621478845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f833f1071891f3%3A0x810711aa7b1ed720!2sFresh&#39;n%20Green!5e0!3m2!1ses-419!2smx!4v1628714906005!5m2!1ses-419!2smx"
          width="100%" height="290" style="border:0;" allowfullscreen="" loading="lazy">
        </iframe>
      </div>
    </div>
  </section>

  <!-- SECCIÓN: Información sobre Campeche (contenido extenso para SEO) -->
  <section class="info-campeche container mb-5">
    <h2 class="mb-3">Acerca de Campeche</h2>
    <p>
      Campeche es una hermosa ciudad ubicada en la península de Yucatán, con una rica herencia colonial
      que se refleja en sus murallas, baluartes y calles adoquinadas. Fundada en 1540, su historia y arquitectura
      la han convertido en Patrimonio Cultural de la Humanidad por la UNESCO en 1999.
    </p>
    <p>
      El centro histórico de Campeche se caracteriza por sus coloridas fachadas, balcones de hierro forjado
      y un ambiente tranquilo que invita a recorrer sus calles. La calle 59 es uno de los ejes principales
      de la vida cultural y gastronómica de la ciudad, concentrando restaurantes, cafés y galerías de arte.
      Además, en sus alrededores encontrarás museos como el Baluarte de la Soledad y el Fuerte de San Miguel,
      donde podrás conocer más sobre la arqueología y la historia maya.
    </p>
    <p>
      Para los amantes de la buena cocina, la gastronomía campechana ofrece platos como el pan de cazón,
      la cochinita pibil, los tamales de chaya y una gran variedad de mariscos y pescados frescos provenientes
      del Golfo de México.
    </p>
    <p>
      Campeche también ofrece turismo ecológico y de aventura en sus reservas naturales y costas,
      destacando Los Petenes, una de las áreas de humedales más importantes de la región con manglares,
      avistamiento de aves y paseos en lancha.
    </p>
    <!-- Oportunidad para colocar más imágenes de la ciudad o de los platillos típicos -->
    <!-- <img src="assets/campeche-centro.jpg" alt="Vista del centro histórico de Campeche" class="img-fluid mb-3"> -->
  </section>

  <section class="texto-adicional container my-5">
    <h2 class="mb-3">Fresh and Green Campeche: Comida Saludable y Cultura Colonial</h2>
    <div class=".imagenEnsalada">
       <img src="assets/ensaladagrande.jpg" class="d-block w-100" alt="Imagen 3 de Fresh and Green Campeche">
    </div>

    <p>
      En <strong>Fresh and Green Campeche</strong> nos apasiona servir
      <em>comida saludable y nutritiva</em> en un entorno único. Ubicados en la icónica
      <strong>calle 59</strong> del <strong>centro histórico de Campeche</strong>,
      ofrecemos platillos frescos elaborados con ingredientes locales de la más alta calidad.
      Nuestra misión es crear una <strong>experiencia gastronómica</strong> que combine la tradición
      cultural de la ciudad con un estilo de vida saludable.
    </p>

    <h3 class="mb-3">Ubicación privilegiada en el centro histórico</h3>
    <p>
      La <strong>calle 59</strong> es conocida por sus coloridas fachadas, restaurantes,
      cafeterías y ambiente bohemio que atrae tanto a turistas como a locales. Pasear por esta
      calle peatonal te permite apreciar la <strong>arquitectura colonial</strong> y sentir la
      historia que envuelve a la ciudad de <strong>Campeche</strong>. Además, nuestra ubicación
      te coloca a pocos minutos de atracciones emblemáticas como la <em>Puerta de Tierra</em>,
      el <em>Baluarte de la Soledad</em> y el <em>Fuerte de San Miguel</em>.
    </p>

    <h3 class="mb-3">Patrimonio de la Humanidad</h3>
    <p>
      <strong>Campeche</strong> fue declarada <strong>Patrimonio Cultural de la Humanidad</strong>
      por la UNESCO en 1999 gracias a su excelente conservación. La ciudad, antiguamente amurallada
      para protegerse de los ataques piratas, aún preserva sus murallas y baluartes en el centro
      histórico. Caminar por sus <em>callejones adoquinados</em> es transportarse al pasado,
      admirando la armonía de sus edificaciones coloniales y el cálido color de sus fachadas.
    </p>

    <h3 class="mb-3">Gastronomía campechana y opciones saludables</h3>
    <p>
      La <strong>gastronomía de Campeche</strong> se caracteriza por sus exquisitos sabores y
      recetas tradicionales, como el <em>pan de cazón</em>, la <em>cochinita pibil</em> y los
      <em>tamales de chaya</em>. Con una rica oferta de <strong>mariscos y pescados</strong>
      frescos provenientes del <strong>Golfo de México</strong>, la región ofrece diversidad culinaria
      que encanta a los visitantes.
    </p>
    <div class=".imagenEnsalada">
      <img src="assets/ordendepollo.jpg" class="d-block w-100" alt="Imagen 3 de Fresh and Green Campeche">
   </div>

    <h3 class="mb-3">Más que un restaurante: una experiencia</h3>
    <p>
      Al visitar <strong>Fresh and Green Campeche</strong>, no solo te deleitarás con
      <em>comida fresca y saludable</em>, sino que también disfrutarás del entorno
      cultural que ofrece la ciudad. Nuestro restaurante se inspira en el encanto colonial
      y la historia de <strong>Campeche</strong>, reflejándose en nuestro diseño interior,
      ambiente relajado y atención personalizada.
    </p>
    <p>
      Además, la <strong>calle 59</strong> es el epicentro de diversas actividades culturales,
      exposiciones de arte y muestras musicales que convierten tu visita en un plan completo:
      come sano, pasea por el centro y sumérgete en la riqueza histórica de la región.
    </p>

    <h3 class="mb-3">Un destino turístico y de ecoturismo</h3>
    <p>
      Más allá del centro histórico, <strong>Campeche</strong> ofrece opciones de
      <em>ecoturismo y aventura</em> en reservas naturales como <em>Los Petenes</em>,
      donde abundan los manglares y la biodiversidad única. Visitar esta zona te permitirá
      observar aves exóticas, navegar en lancha y descubrir la belleza natural de la región.
      También hay <em>cenotes</em> y playas que complementan la experiencia de
      quienes buscan contacto con la naturaleza.
    </p>

    <h3 class="mb-3">Visítanos y descubre Campeche</h3>
    <p>
      Te invitamos a vivir esta <strong>experiencia única</strong> que fusiona la
      <em>cultura colonial</em>, la <em>gastronomía saludable</em> y la
      <em>belleza natural</em> de la región. En <strong>Fresh and Green Campeche</strong>,
      nos esforzamos cada día para ofrecerte la mejor calidad en cada platillo, un servicio
      cálido y un ambiente que te hará sentir como en casa.
    </p>
    <p>
      ¡No esperes más! <strong>Visítanos en la calle 59</strong> del
      <strong>centro histórico de Campeche</strong> y déjanos ser parte de tu viaje
      gastronómico y cultural. Estamos listos para consentirte con comida fresca,
      nutritiva y llena de sabor, en una ciudad que combina historia y modernidad
      como pocas en el mundo.
    </p>
  </section>

</div>


<app-footer></app-footer>
