import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-coctelfrutas',
  templateUrl: './dialog-coctelfrutas.component.html',
  styleUrls: ['./dialog-coctelfrutas.component.css']
})
export class DialogCoctelfrutasComponent implements OnInit {


  ingredientess: Array<any> = [
    'sandia','melón','piña','papaya','platano'
  ];
  todos: any;
  selectedingredientes = [] as any;
  coctelFrutasForm:FormGroup;
  get ingredientesArray(): any{

    return this.coctelFrutasForm.get('ingredientes')as FormArray;

  }
  constructor(public fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogCoctelfrutasComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.coctelFrutasForm = this.fb.group({
        ingredientes : this.addingredientes(),
        seleccionados: new FormControl('', Validators.required),
        tipo: new FormControl('', Validators.required),
      });
     }

  ngOnInit(): void {
    this.coctelFrutasForm = this.fb.group({
      ingredientes : this.addingredientes(),
      seleccionados: new FormControl('', Validators.required),
      tipo: new FormControl('', Validators.required),
    });
  }
  getselectedingredientes(): any{
    this.selectedingredientes = [] as any ;
    this.ingredientesArray.controls.forEach((control: any, i: any) => {
      if (control.value){
        this.selectedingredientes.push(this.ingredientess[i]);
      }
    });
    this.coctelFrutasForm.controls['seleccionados'].setValue(this.selectedingredientes.join(', '));
  }
  addingredientes(): any{
    const arr = this.ingredientess.map( element => {
      return this.fb.control(false);
    });
    return this.fb.array(arr);
  }
  close(){
    this.dialogRef.close();
  }
  save(){


    if (this.coctelFrutasForm.value.ingredientes === '' && this.coctelFrutasForm.value.seleccionados === ''&& this.coctelFrutasForm.value.seleccionados === ''  ){
      console.log('no hay datos: secundario');
    }

    if (this.coctelFrutasForm.value){
      this.dialogRef.close(this.coctelFrutasForm.value);
    }

  }

}
